import { CartItem } from "../../../types/CartItem";
import type { Order } from "../../../types/Order";
import type { Cart } from "../../../types/ShoppingCart";

export const calculateTotalPrice = (container: Cart | Order) => {
  return (
    container.items
      .map((item) => {
        return (
          item.quantity *
          item.product.priceFrom *
          (1 - (item.product.discount || 0))
        );
      })
      .reduce((acc, price) => {
        return acc + price;
      }, 0) + (container.shippingMethod ? container.shippingMethod?.price : 0)
  );
};

export const calculatePrice = (priceFrom: number, discount?: number) => {
  if (discount && discount > 0) {
    return {
      priceFrom: priceFrom,
      hasDiscount: true,
      discountPercentage: discount * 100,
      priceWithDiscount: priceFrom * (1 - discount),
    };
  }
  return {
    priceFrom: priceFrom,
    hasDiscount: false,
    discountPercentage: 0,
    priceWithDiscount: priceFrom,
  };
};
